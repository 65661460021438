import React,{useEffect, useState} from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { BASEURL,SITEURL } from './BaseURL';
import { GETUSERDATA } from 'redux/constants/Theme';
import { connect } from "react-redux";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App(props) {
  const {userdata, USERDATA, GETUSERDATA} = props;
  const [profileData, setprofileData] = useState();

  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  const fetchData = async () =>{
		const myHeaders = new Headers();
		const token = localStorage.getItem('usertoken'); 
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Authorization', token);   
		await  fetch(BASEURL+'getProfileData/'+localStorage.getItem('userUUID'), {
			method: "GET",
			headers: myHeaders,
		}).then(response => response.json())
		.then(res => {
			if(res.status===201){

				// setprofileData(res.data);
        const favicon = getFaviconEl(); // Accessing favicon element
        // console.log(profileData[0].favicon);
        if(res.data && res.data[0].favicon!==""){
          favicon.href = BASEURL+"uploads/favicon/"+res.data[0].favicon;      
        }else{
          favicon.href = SITEURL+"favicon.ico";
        }
			}		
		}).catch(err => console.log(err))      
	}   
  
  useEffect(() => {    
    if(localStorage.getItem('usertoken')!=null){
      fetchData();   
    }
  }, [])
  
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/" component={Views}/>
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;

// const mapStateToProps = ({ theme }) => {
//   const { userdata } =  theme;
//   return { userdata }
// };

// const mapDispatchToProps = {
//   GETUSERDATA,
// 	// updateBalance,
//   // toggleCollapsedNav,
//   // onMobileNavToggle
// }

// export default connect(mapStateToProps, mapDispatchToProps)(App);