import { 
  DashboardOutlined, 
  FileTextOutlined,
  DotChartOutlined,
  MailOutlined,
  SettingOutlined,
  LayoutOutlined,
  UploadOutlined, 
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { BASEURL } from '../BaseURL';

var dashBoardNavTree = [];


var menudata = [];
const getMenu = async() => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  let role = localStorage.getItem('role');
  const res = await fetch(BASEURL+'getmenu/'+role, {
    method: "GET",
    headers: myHeaders,    
  });
  
  const data = await res.json();
  // console.log(data.menu);return false;
  if(data.status != 422 || !data){
    // setmenu(data.menu);
    // setsubmenu(data.submenu);
    
    for (let index = 0; index < data.menu.length; index++) {
      var obj = {};
      const element = data.menu[index];
      // console.log(element.icon);
      obj["key"] = element.title;
      obj["path"] = `${APP_PREFIX_PATH}/${element.url}`;
      obj["title"] = element.title;
      obj["icon"] = element.icon;
      obj["breadcrumb"] = false;
      obj["submenu"] = [];
      menudata.push(obj);      
    }  
   
  }	
}
  // console.log(menudata);
// }
// getMenu();
let role = localStorage.getItem('role');
// if(role == 0 || role == 1){
  
  dashBoardNavTree = [
    {
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [  
    ]
  },
    {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'Users',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [  
    ]
  },
    {
    key: 'numbers',
    path: `${APP_PREFIX_PATH}/numbers`,
    title: 'Numbers',
    icon: PhoneOutlined,
    breadcrumb: false,
    submenu: [  
    ]
  },
  // {
  //     key: 'outbound',
  //     title: 'Outbound',
  //     icon: UploadOutlined,
  //     breadcrumb: false,
  //     submenu: [
  //     {
  //     key: 'ipmanagement',
  //     path: `${APP_PREFIX_PATH}/ip-management`,
  //     title: 'IP Management',
  //     breadcrumb: false,
  //     submenu: [  
  //     ]
  //   }]
  // },
  //   {
  //   key: 'detail-calls',
  //   path: `${APP_PREFIX_PATH}/detail-calls`,
  //   title: 'Detail Call',
  //   icon: FileTextOutlined,
  //   breadcrumb: false,
  //   submenu: [  
  //   ]
  // },
    {
    key: 'voice',
    title: 'Voice',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'logs',
        title: 'Logs',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [{
          key: 'detailcalls',
          path: `${APP_PREFIX_PATH}/voice/detail-calls`,
          title: 'Detail Call',
          breadcrumb: false,
          submenu: []
        },     
        {
          key: 'summary',
          path: `${APP_PREFIX_PATH}/voice/summary`,
          title: 'Summary',
          breadcrumb: false,
          submenu: []
        },]
      }, 
      {
        key: 'overview',
        path: `${APP_PREFIX_PATH}/voice/overview`,
        title: 'Overview',
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: []
      }, 
    ]
  },

  {
    key: 'messaging',
    title: 'Messaging',
    breadcrumb: false,
    submenu: [
      {
        key: 'messagingOverview',
        path: `${APP_PREFIX_PATH}/messaging/overview`,
        title: 'Overview',
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: []
      },     
    ]
  },

  {
      key: 'apps-mail',
      title: 'Trunk',
      breadcrumb: false,
      submenu: [
      // {
      //   key: 'TrunkOverview',
      //   path: `${APP_PREFIX_PATH}/trunk/overview`,
      //   title: 'Overview',
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: []
      // },
      {
        key: 'TrunkInbound',
        path: `${APP_PREFIX_PATH}/trunk/inbound`,
        title: 'Inbound',
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: []
      },{
        key: 'TrunkOutbound',
        path: `${APP_PREFIX_PATH}/trunk/outbound`,
        title: 'Outbound',
        icon: UploadOutlined,
        breadcrumb: false,
        submenu: [{
          key: 'IPAccessControl',
          path: `${APP_PREFIX_PATH}/trunk/outbound/IPAccessControl`,
          title: 'IP Access Control',
          breadcrumb: false,
          submenu: []
        },{
          key: 'SIPUsers',
          path: `${APP_PREFIX_PATH}/trunk/outbound/sip-users`,
          title: 'SIP Users',
          breadcrumb: false,
          submenu: []
        },{
          key: 'SIPTrunk',
          path: `${APP_PREFIX_PATH}/trunk/outbound/SIP-trunk`,
          title: 'SIP Trunk',
          breadcrumb: false,
          submenu: []
        }]
      },]
    }, 

  //   {
  //     key: 'PhoneNumber',
  //     title: 'Phone Number',
  //     icon: MailOutlined,
  //     breadcrumb: false,
  //     submenu: [{
  //       key: 'PhoneNumberOverview',
  //       path: `${APP_PREFIX_PATH}/numbers/overview`,
  //       title: 'Overview',
  //       icon: PhoneOutlined,
  //       breadcrumb: false,
  //       submenu: [
  //         {
  //           key: 'PhoneNumberOverview',
  //           path: `${APP_PREFIX_PATH}/numbers/listing`,
  //           title: 'Numbers',
  //           breadcrumb: false,
  //           submenu: []
  //         }
  //       ]
  //     },    
  //   ]
  //   }, 
    
    {
      key: 'Support',
      path: `${APP_PREFIX_PATH}/support`,
      title: 'Support',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [  
      ]
    },
    {
      key: 'Billing',
      path: `${APP_PREFIX_PATH}/billing`,
      title: 'Billing',
      icon: FileTextOutlined,
      breadcrumb: false,
      submenu: [  
      ]
    },
    {
      key: 'Setting',
      path: `${APP_PREFIX_PATH}/settings`,
      title: 'Setting',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: [  
      ]
    },
  
  ]

  // console.log(dashBoardNavTree);
// }else if(role == 2){
//   dashBoardNavTree = [
//   {
//     key: 'SupportDashboard',
//     path: `${APP_PREFIX_PATH}/support-dashboard`,
//     title: 'Dashboard',
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [   
//     ]
//   },
//   {
//     key: 'Support',
//     path: `${APP_PREFIX_PATH}/support`,
//     title: 'Support',
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [   
//     ]
//   },
// ]; 
// }

export default dashBoardNavTree;
