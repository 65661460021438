import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {BASEURL} from '../../BaseURL'; 
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	GETUSERDATA,
	GETBALANCE,
	UPDATEBALANCE,
} from '../constants/Theme';
import {	
	showAuthMessage,
	supportauthenticated,
	authenticated,
	signOutSuccess,
	// signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";
import {
	getuserdatalist,
	getBalance,
} from "../actions/Theme";

import FirebaseService from 'services/FirebaseService'

export function* getuserdata() {
	yield takeEvery(GETUSERDATA, function* () {
	try {
			const myHeaders = new Headers();
			const userid = localStorage.getItem('userUUID')
			const token = localStorage.getItem('usertoken'); 
			// console.log(token);
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Authorization', token);   
			
			const res = yield fetch(BASEURL+'getProfileData/'+userid, {
				method: "GET",
				headers: myHeaders,
			}).then(response => response.json())
			.then(res => {
				if(res.status===201){
	
					this.setState({profileData:res.data});
					localStorage.setItem('avatar',res.data[0].avatar);   
				}		
			}).catch(err => console.log(err))      
					
		const data = yield res.json(); 
		if(data.status == 201){
			yield put(getuserdatalist(data.data));
		}
	  } catch (error) {
			yield put(getuserdatalist([]));
	  }
	});
}

export function* updateBalance() {
	yield takeEvery(UPDATEBALANCE, function* () {
	// console.log(123);
	try {
			const myHeaders = new Headers();
			const userid = localStorage.getItem('userID')
			const token = localStorage.getItem('usertoken'); 
			// console.log(token);
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Authorization', token);           
			const res = yield fetch(BASEURL+'gettopbardata', {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
				userID:userid
				})
			});

		const data = yield res.json(); 
		if(data.status == 201){
			// setbalance(data.data[0].balance);
			localStorage.setItem(GETBALANCE, data.data[0].balance);	
			// console.log(localStorage.getItem(GETBALANCE));
			yield put(getBalance(data.data[0].balance));
			// return data.data[0].balance;
		}
	  } catch (error) {
			localStorage.setItem(GETBALANCE, 0);	
			yield put(getBalance(0));
	  }

	
		
	});
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		// console.log(email, password);return;
		try {
			// const user = yield call(FirebaseService.signInEmailRequest, email, password);
			const user = yield fetch(BASEURL+'admin-signin', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",            
				},
				body: JSON.stringify({
					type:1,email, password
				})
			});
	
			const data = yield user.json();
			// console.log(data); return
			// let sessdata = [];
			// var myHour = new Date();
			// myHour.setHours(myHour.getHours() + 1); //one hour from now
			// sessdata.push(myHour);
			// console.log(data);return false;
			if(data.status === 422 || !data || data.error){
				yield put(showAuthMessage(data.error));            
			}else{           
				// console.log(2);return
					// console.log(data.id); return
					localStorage.setItem('usertoken',data.usertoken);             
					localStorage.setItem('emailID',data.email);             
					localStorage.setItem('userID',data.id);             
					localStorage.setItem('userUUID',data.uuid);             
					localStorage.setItem('userName',data.username);             
					localStorage.setItem('totalLogin',0);             
					localStorage.setItem('name',data.name);             
					localStorage.setItem('avatar',data.avatar);             
					localStorage.setItem('role',data.role);             
					localStorage.setItem('timezone',JSON.stringify(data.timezone));                     
					localStorage.setItem(AUTH_TOKEN, data.usertoken);
					
					// localStorage.setItem('storedData', JSON.stringify(sessdata))
					if(data.role == 2){
						yield put(supportauthenticated(data.usertoken));					
					}else{
						yield put(authenticated(data.usertoken));				
					}
			};
			// console.log(user.user.uid);return;
			// if (user.message) {
			// 	yield put(showAuthMessage(user.message));
			// } else {
			// 	localStorage.setItem(AUTH_TOKEN, user.user.uid);
			// 	yield put(authenticated(user.user.uid));
			// }
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {	
  yield takeEvery(SIGNOUT, function* () {
	// console.log('123');return;
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.clear();
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem('usertoken');             
				localStorage.removeItem('emailID');             
				localStorage.removeItem('userID');             
				localStorage.removeItem('userUUID');             
				localStorage.removeItem('userName');             
				localStorage.removeItem('totalLogin');             
				localStorage.removeItem('name');             
				localStorage.removeItem('avatar');             
				localStorage.removeItem('role');             
				localStorage.removeItem('timezone');             
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		// console.log(email, password);return;
		try {
			// const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			const user = yield fetch(BASEURL+'signup', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",            
                },
                body: JSON.stringify({
                   email, password
                })
            });

            const data = yield user.json();
            
            if(data.status === 422 || !data || data.error){
                yield put(showAuthMessage(data.error));          
            }else{
				yield put(showAuthMessage(data.message));    
				// console.log(data.id);return
                // localStorage.setItem(AUTH_TOKEN, data.token);
				// yield put(signUpSuccess(data.token));
				// localStorage.setItem(AUTH_TOKEN, data.token);
				// localStorage.setItem('usertoken',data.token);    
				// yield put(authenticated(data.token));		
            }

			// if (user.message) {
			// 	yield put(showAuthMessage(user.message));
			// } else {
			// 	localStorage.setItem(AUTH_TOKEN, user.user.uid);
			// 	yield put(signUpSuccess(user.user.uid));
			// }
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(updateBalance),
		fork(getBalance),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
